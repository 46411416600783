import React from 'react'

const Nav = () => {
        function moveTo(target, options) {
      
        var start = window.pageYOffset,
                   opt = {
                       duration: options.duration || 500,
                       offset: options.offset || 0,
                       callback: function () { },
                       easing: options.easing || easeInOutQuad
                   },
                   distance = target.getBoundingClientRect().top - document.getElementById('header').clientHeight+1,
                   duration = typeof opt.duration === 'function' ? opt.duration(distance) : opt.duration,
                   timeStart, timeElapsed;
        requestAnimationFrame(function (time) {
            timeStart = time;
            loop(time);
        });
    
        function loop(time) {
            timeElapsed = time - timeStart;
            window.scrollTo(0, opt.easing(timeElapsed, start, distance, duration));
            if (timeElapsed < duration) {
                requestAnimationFrame(loop);
            } else
                end();
        }
    
        function end() {
            window.scrollTo(0, start + distance);
            if (typeof opt.callback === 'function') ;
            opt.callback();
        }
    
        function easeInOutQuad(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t * t + b;
            t -= 2;
            return c / 2 * (t * t * t + 2) + b;
        }
    }
    
    const handleScroll=(e,ele)=>
    {
        e.preventDefault();
        moveTo(document.getElementById(ele), { duration: 1000 })
        document.getElementById('nav-toggle').classList.remove('active')
        document.getElementById('nav').classList.remove('active') 
    }
  return (
    <>
                  <li><a   onClick={(e)=>handleScroll(e,'loyalty')} href="loyalty">Rate Review</a></li>
                <li><a   onClick={(e)=>handleScroll(e,'process')} href="process">Process</a></li>
                <li><a   onClick={(e)=>handleScroll(e,'benefits')} href="benefits">Benefits</a></li>                
                <li><a onClick={(e)=>handleScroll(e,'expertise')} href="expertise">Expertise</a></li>
                <li><a onClick={(e)=>handleScroll(e,'lenders')} href="lenders">Lenders</a></li>
                <li><a onClick={(e)=>handleScroll(e,'solution')} href="solution">Solutions</a></li>                
                <li><a onClick={(e)=>handleScroll(e,'about')}href="about">The Team</a></li>
                <li className='cnt'><a className="contact" id="contact" href="#">Contact Us</a></li>
                <li className='insta'><a href="https://www.instagram.com/finsavvyfinance/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='#2186f2'><path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z"/></svg></a></li>
    </>
  )
}

export default Nav
