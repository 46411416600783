import React,{useRef} from 'react';
import Nav from './nav';

const Header = () => {
    const refHeader=useRef();
    const refNav=useRef();
    const refTog=useRef()

    const handleToggle=(e)=>
    {
        e.preventDefault();
         if(refTog.current.classList.contains('active'))
         {
            refTog.current.classList.remove('active')
            refNav.current.classList.remove('active')
         }
         else{
            refTog.current.classList.add('active')
            refNav.current.classList.add('active') 
         }

    }

  return (
    <header id="header" ref={refHeader}>
    <div className="container fullm">
        <a id="nav-toggle" ref={refTog} className="middle-h" onClick={(e)=>handleToggle(e)}>  
            <span></span>
        </a>
        <a id="logo">
            <img className="img img-w lazyImg" src="./images/logox.png" data-src="./images/logo.png"
                alt="Finsavvy logo"/>
            <img className="img  img-b lazyImg" src="./images/logo-bx.png" data-src="./images/logo-b.png"
                alt="Finsavvy logo"/>
        </a>
        <nav id="nav" ref={refNav}>
            <ul id="ul">
                <Nav/>
            </ul>
        </nav>
    </div>
    </header>
  )
}

export default Header
