
import React, { useEffect } from "react";
import Header from "./components/header";
import Topsection from "./components/topsection";
import Process from "./components/process";
import Expertise from './components/expertise'
import Logos from "./components/logos";
import About from "./components/about";
import Form from "./components/form";
import Footer from "./components/footer";
import Benefits from "./components/benefits";
import Solution from "./components/solution";


function App() {


  useEffect(() => {
    const iphone = document.getElementById('iphone')
    const header = document.getElementById('header')
    const closeEnq = document.getElementById('closeEnq');
    const enq = document.getElementById('enquire');
    const closeEnqBtn = document.getElementById('closeEnqBtn');
    const contact = document.getElementById('contact')
    const contactF = document.getElementById('contactF')
    contact.addEventListener('click', function (e) { e.preventDefault(); openEnq() })
    contactF.addEventListener('click', function (e) { e.preventDefault(); openEnq() })
    closeEnq.addEventListener('click', function (e) { e.preventDefault(); CloseEnq() })
    closeEnqBtn.addEventListener('click', function (e) { e.preventDefault(); CloseEnq() })
    var i = 0;
    var allowAnimation = false
    var delay = 4000;
    var firstTime = true

    const animationList = [
      {
        exeFunction: function () {

          iphone.classList.add('animtate1')
          setTimeout(function () {
            iphone.classList.add('animtate2')
          }, 500)
        }
      },
      {
        exeFunction: function () {

          iphone.classList.remove('animtate2')
          setTimeout(function () {
            iphone.classList.remove('animtate1')
          }, 500)

        }
      }
    ]

    function runAnimation() {

      if (i === 0) {
        if (firstTime) {
          delay = 4000;
          firstTime = false
        }
        else
          delay = 13000
      }

      else
        delay = 4000


      if (!allowAnimation) return

      setTimeout(function () {
        if (i === 2) i = 0;
        animationList[i].exeFunction();
        runAnimation();
        i++
      }, delay);
    }


    function checkHeader(y) {
      if (!header.classList.contains('fixed') && y > 5)
        header.classList.add('fixed')
      else if (header.classList.contains('fixed') && y <= 5)
        header.classList.remove('fixed')

    }
    function openEnq() {
      enq.style.transform = `translateX(0%)`;
      closeEnq.style.display = `block`
      setTimeout(function () {
        closeEnq.style.opacity = `1`;
      }, 5)

    }
    function CloseEnq() {
      closeEnq.style.opacity = `0`;
      enq.style.transform = `translateX(105%)`;
      setTimeout(function () {
        closeEnq.style.display = `none`
      }, 705)

    }
    document.body.classList.add('loaded');
    let phoneIterval;


    if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype) {

      let animatePhone = new IntersectionObserver(function (entery) {
        if (entery[0].isIntersecting) {

          //phoneIterval = setInterval(handlePhoneAnimation, 6000);
          allowAnimation = true
          runAnimation()
        }
        else
          allowAnimation = false
      });
      animatePhone.observe(iphone)
      let lazyImageObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            let ele = entry.target;
            if (ele.classList.contains('loaded'))
              return
            let img = new Image();
            img.src = ele.dataset.src

            img.onload = function () {
              ele.classList.add('loaded')
              ele.src = ele.dataset.src
            }

          }
        });
      })
      let lazyImages = document.getElementsByClassName('lazyImg');

      Array.from(lazyImages).forEach((el) => {
        lazyImageObserver.observe(el)

      });
    }
    let scrollY = 0;
    let ticking = false
    document.addEventListener("scroll", (event) => {
      scrollY = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          checkHeader(scrollY);
          ticking = false;
        });

        ticking = true;
      }
    });

  }, []);
  return (
    <>
      <Header />
      <Topsection />
      <Process />
      <Benefits/>
      <Expertise />
   
      <Logos />
      <Solution/>
      <About />
      <Form />
      <Footer />
    </>
  );
}

export default App;

