import React,{useRef} from 'react'

const Expertise = () => {
  const popRef=useRef();
  const popContent=useRef();
  const items = [
    {
      title: 'Refinance',
      svg: <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
        <path d="M430 974q-72-9-134.5-43t-108-86.5Q142 792 116 723.5T90 576q0-88 41.5-168T243 266H121v-60h229v229h-60V306q-64 51-102 121.5T150 576q0 132 80 225.5T430 913v61Zm-7-228L268 591l42-42 113 113 227-227 42 42-269 269Zm187 200V717h60v129q64-52 102-122t38-148q0-132-80-225.5T530 239v-61q146 18 243 129t97 269q0 88-41.5 168T717 886h122v60H610Z"></path>
      </svg>,
      headLine: `Your current loan may have been the best product with the lowest rate when you obtained it, however it may not be the best solution in the current market.`,
      texts: [`Your current loan may have been the best product with the lowest rate when you obtained it, however it may not be the best solution in the current market. It is common knowledge that lenders offer new customers a lower rate than existing customers, and you might be on that higher interest rate right now.`,
        `Fortunately, the intense competition between lenders has resulted in exclusive interest rate discounts and fast approvals for Finsavvy Finance clients, and we will use this to your advantage. These offers are not accessible for the public, and we can help you to secure a great rate that is lower than what you’d find yourself.`,
        `By reducing your interest rate, we can help you lower your repayments, or alternatively, accelerate the repayment of your home loan while keeping the repayments the same. And with our regular rate review guarantee, you will remain ahead for the life of your loan.`,`It's likely that your financial situation has changed since you first obtained your loan. We’ll assess your current position and explore new options that may be beneficial to you. This may include leveraging your property's appreciation by accessing equity to fund renovations or your next property purchase. Or, we could consolidate your credit card and personal loan debts into your home loan to reduce your overall repayments and simplify the management of multiple debts.`
      ]

    },
    {

      title: 'Owner Occupied',
      headLine: `Are you tired of renting and feeling like you're throwing money away every month? It's time to consider owning your own home, and an owner-occupied property is the perfect place to start.`,
      svg: <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
        <path d="M220 876h150V626h220v250h150V486L480 291 220 486v390Zm-60 60V456l320-240 320 240v480H530V686H430v250H160Zm320-353Z"></path>
      </svg>,
      texts: [`As a homeowner, one of the biggest advantages you'll enjoy is the ability to build equity in your home. Equity is essentially the amount of your home's value that you own outright, and it can be a powerful tool for building wealth and securing your financial future.`,
         `One of the most exciting things about owning your own home is the potential for your property's value to appreciate over time. As the property market grows and demand for housing increases, your home's value could increase as well, helping you to create wealth. This can offer a range of financial opportunities down the line. For example, you could fund home renovations, debt consolidation, education expenses, your next car, holiday, and even your next property.`,
        `By investing in an owner-occupied property, you can take advantage of the long-term stability and enjoy the many benefits of owning your own home. You won’t have to worry about your landlord or the rental market. In contrast, owning your own home provides a sense of permanence and security that can help you weather life's ups and downs.`,
      `No matter what your financial situation may be, our experienced team has dealt with it before and is equipped to partner with you to secure your owner-occupied property and help you achieve your financial goals.`]
    }
    ,
    {

      title: 'Investment Loans',
      svg: <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
        <path d="M770 701V379L560 232 350 379v129h-60V349l270-193 270 193v352h-60ZM560 232Zm20 204h40v-40h-40v40Zm-80 0h40v-40h-40v40Zm80 80h40v-40h-40v40Zm-80 0h40v-40h-40v40Zm66 478-311-89v57H40V568h309l255 96q27 10 45.5 32.5T668 761h114q42 0 70 30t28 81v26l-314 96Zm-466-92h94V628h-94v274Zm462 30 256-78q-6-19-15-26t-21-7H575q-30 0-55.5-4T471 806l-81-25 22-58 73 24q25 8 47.5 11t71.5 3q0-12-4.5-23.5T584 721l-245-93h-84v214l307 90ZM194 765Zm410-4Zm-410 4Zm61 0Z"></path>
      </svg>,
      headLine: `As dedicated Investment Loan specialists, our team is well-equipped to provide expert guidance and support throughout every step of your journey.`,
      texts: [`As dedicated Investment Loan specialists, our team is well-equipped to provide expert guidance and support throughout every step of your journey.`,
        `If are trying to figure out if it is possible to buy your next investment, we can work that out for you. It is a common misconception that a cash deposit is always required when acquiring a new property, but we can explore alternative options such as utilizing the equity from your existing property as a deposit. Additionally, we can assess the potential rental income of your investment and demonstrate how this can be used to manage your loan repayments.`,
        `When it comes to identifying the ideal location to invest, our team can provide you with valuable insights and connect you with reputable industry experts to assist in making an informed decision. Key considerations for selecting a property include rental yield, vacancy rates, expectation for capital gains, and town planning. To aid in your decision-making process, we provide you with detailed property and suburb reports at no cost, ensuring you have access to the most comprehensive information available.`,
        `Finally, once you have made an offer on a property, the structure of your investment loan is a critical component to consider. With our extensive experience in financing investment properties, we can help you navigate the complex process of selecting the most appropriate loan and ownership structure for your needs. By leveraging our expertise, you can maximize tax deductions and minimize interest costs, setting you on the path to investment success.`
      ]
    }, {

      title: 'Construction Loans',
      svg: <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
        <path d="M212 815V476h60v339h-60Zm242 0V476h60v339h-60ZM80 935v-60h800v60H80Zm608-120V476h60v339h-60ZM80 416v-53l400-228 400 228v53H80Zm134-60h532-532Zm0 0h532L480 204 214 356Z"></path>
      </svg>,
      headLine: `A construction loan is the perfect solution for anyone looking to build their dream home from scratch or undertake extensive renovations.`,
      texts: [`A construction loan is the perfect solution for anyone looking to build their dream home from scratch or undertake extensive renovations.`,
        `With a construction home loan, you have the flexibility to borrow money when you need it during the building process, and only pay interest on the amount that has been drawn down. This means that you can save on interest costs during the construction phase and focus on bringing your dream home to life.`,
        `In some cases, the lender may be willing to lend up to the entire amount needed for building costs, as long as they are confident in the estimated future value of the property and your ability to make payments on the new loan.`,
        `We also offer a range of features and benefits to help make the process as smooth as possible. Our experienced team will work with you to ensure you have the right loan structure in place to suit your individual needs and financial goals.`,
        `We know that building a home can be a daunting task, but we provide ongoing support throughout the entire process to ensure that everything runs smoothly. We work with a network of trusted builders, developers and other industry professionals, so you can be confident that you're in good hands.`
      ]
    }
    ,
    {

      title: 'Pre-approvals',
      svg:
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m346 996-76-130-151-31 17-147-96-112 96-111-17-147 151-31 76-131 134 62 134-62 77 131 150 31-17 147 96 111-96 112 17 147-150 31-77 130-134-62-134 62Zm27-79 107-45 110 45 67-100 117-30-12-119 81-92-81-94 12-119-117-28-69-100-108 45-110-45-67 100-117 28 12 119-81 94 81 92-12 121 117 28 70 100Zm107-341Zm-43 133 227-225-45-41-182 180-95-99-46 45 141 140Z" /></svg>,
      headLine: `Similar to how you’d check how much fuel you have in the tank before going on a road trip, a pre-approval will help you ...`,
      texts: [`Similar to how you’d check how much fuel you have in the tank before going on a road trip, a pre-approval will help you to determine your purchasing power and will ensure that you have enough resources to acquire the property you desire. `,
        `Once you find a home that you want to purchase, having pre-approval for financing can speed up the process. You'll be able to make an offer right away, without having to wait for financing to be approved. This can help you avoid losing out on a property to someone who is able to move more quickly.`,
        `In a competitive real estate market, having a pre-approval for financing will give you an edge over other potential buyers. Sellers will take your offer more seriously if they see that you have already taken steps to secure financing, which can help you stand out from other buyers. It shows that you’re serious about the offer you’ve made and that it is less likely to be withdrawn, making you a more attractive bidder and increasing your chances of purchasing the property. `,
        `Every lender has different credit policies that result in varying maximum loan capacities. Navigating these differences can be time-consuming and confusing, so we offer a streamlined solution. With just one review of your finances, we can quickly calculate the maximum amounts over 30 lenders are willing to lend to you. This saves you endless hours of searching for options yourself. Once we have determined the best lender for your needs, we can prepare and submit an application to confirm and lock in your loan amount. `]
    }
    , {

      title: 'First Home Buyers',
      svg: <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M160 896V476H66l414-339 414 339h-94v50h-60v-99L480 214 220 427v409h100v60H160Zm408 80L414 822l42-42 112 112 232-232 42 42-274 274Z" /></svg>,
      headLine: `Buying your first home is a major milestone, but navigating the process can be overwhelming.`,
      texts: [`Buying your first home is a major milestone, but navigating the process can be overwhelming. That's why having an experienced guide by your side is essential. At Finsavvy Finance, we understand the complexities of the buying process and are dedicated to providing you with the necessary support and resources needed to make informed decisions every step of the way.`,
        `We provide comprehensive guidance on the various options available to you, explaining every step along the way. Our experts will help you assess your eligibility for grants and explore various financing options to get your finances in order, working tirelessly to help you achieve your goals in a timely manner. We offer additional options such as Guarantor Loans that allow for borrowing up to 100% of the purchase price as well as Stamp Duty, which will mitigate the requirement to pay Lender’s Mortgage Insurance. Our goal is to help you understand your loan and also why we have recommended your specific loan structure. `,
        `It is of utmost importance that we help you own your property sooner than you thought possible. That is why we offer ongoing support even after your loan settles. We can help you with any questions or concerns you may have, and keep you updated on any changes that may affect your loan or property ownership. Our regular rate review guarantee will ensure that your loan remains competitive for the life of the loan, helping you to own your home sooner rather than later.`
      ]
    }

    , {

      title: 'Self Employed Loans',
      svg: <svg  className="icon" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M480 536q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-60q25 0 42.5-17.5T540 416q0-25-17.5-42.5T480 356q-25 0-42.5 17.5T420 416q0 25 17.5 42.5T480 476ZM240 776v-76q0-26 14-45t46-36q42-22 87-32.5t93-10.5q48 0 93 10.5t87 32.5q32 17 46 36t14 45v76H240Zm240-140q-48 0-93 13t-87 39v28h360v-28q-42-26-87-39t-93-13Zm387-222q-32-76-91-135t-134-91l23-55q87 36 154 103t104 155l-56 23Zm-774 0-56-23q37-88 103.5-154.5T295 133l23 56q-79 33-135.5 89.5T93 414Zm202 605q-87-36-154-103T37 761l56-24q33 78 90 135.5T319 963l-24 56Zm370 0-23-56q76-32 134.5-90.5T867 738l56 23q-36 87-103 154t-155 104ZM480 416Zm0 300h180-360 180Z"/></svg>,
      headLine: `At Finsavvy Finance, we understand the unique financial situation of self-employed individuals, and we're here to help you secure the financing you need.
      `,
      texts: [`At Finsavvy Finance, we understand the unique financial situation of self-employed individuals, and we're here to help you secure the financing you need. Traditional lenders often require extensive documentation and a steady income stream, making it challenging for self-employed individuals to secure loans. But with our flexible loan solutions, we can tailor financing to your specific needs.`,
        `Typically, lenders require two full financial years of trading, but we understand that every circumstance is different, and we work with you to determine the ideal strategy for your unique situation. Our expert brokers take the time to fully understand your financial position and work with you to structure your loan application to ensure the highest chance of approval.`,
        `Low doc loans are an alternative source of finance that many self-employed individuals find helpful. If you don't have tax returns completed or if your historical income doesn't reflect your current income, low doc loans may be the solution for you. We work with a variety of lenders and understand the different requirements for low doc loans, from current year BAS statements to declarations of income.`,
        `Our goal is not just to get you the financing you need, but to help you improve your financial situation over the long term. After your low doc loan is complete and you're in a better position, we can work with you to refinance to a more traditional loan, and help you reduce your rate further.`]
    }


    , {

      title: 'Debt Consolidation',
      svg: <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m865 875-45-45V554H544L415 425h405V316H306l-60-60h574q24 0 42 18t18 42v520.163Q880 847 876 857t-11 18ZM401 582Zm162-9Zm-191-19H140v282h514L372 554Zm441 441-99-99H140q-24 0-42-18t-18-42V316q0-24 18-42t42-18h20l60 60h-80v109h103L26 208l43-43 787 787-43 43Z" /></svg>,
      headLine: `Debt consolidation is a smart financial move that can provide you with the opportunity to take control and simplify the management of your finances.`,
      texts: [`Debt consolidation is a smart financial move that can provide you with the opportunity to take control and simplify the management of your finances. Instead of juggling multiple payments with different lenders, you can consolidate your debts into one easy-to-manage payment, saving you time and reducing your stress.`,
        `By consolidating your debts, you may also be able to take advantage of lower interest rates and reduced fees. Your home loan is often a great option for debt consolidation, as it typically offers a lower interest rate than other types of credit.`,
        `At Finsavvy Finance, we understand that everyone's financial situation is different, so we offer flexible solutions that are tailored to your specific needs. If you prefer not to add the debt balances to your home loan, we can structure this portion of your mortgage as an additional split over a shorter term, giving you greater control over your repayment timeline.`,
        `Our team takes care of all the hard work, organizing the consolidation of your debts and paying your nominated credit providers directly, so you can focus on what really matters - achieving your financial goals. Let us help you simplify your financial life and get on track to achieve your dream of financial freedom.`]
    }




  ]

  function openPop(){
    popRef.current.style.display=`flex`;
    setTimeout(function(){
      popRef.current.style.opacity=`1`;
    },5)

}
function closePop(){
  popRef.current.style.opacity=`0`;
    setTimeout(function(){
      popRef.current.style.display=`none`;
    },705)

}
  const handlePoUp=(e,item)=>
  {
    e.stopPropagation();     
   
       let allP=``;
       item.texts.forEach(p=>{
        allP=allP+ `<p>${p}</p>`
       })
       popContent.current.innerHTML='';
       popContent.current.innerHTML=`<h3>${item.title}</h3>${allP}`
      openPop()
  }
  const handleClick = (e) => {
    e.preventDefault();
    let p = e.currentTarget
    if (!p.classList.contains('rotate')) {
   
      p.classList.add('rotate')
    }
    else
      p.classList.remove('rotate')

  }
  return (
    <>

      <section className="ptb150" id="expertise">
        <div className="container">
          <h2 className="center-align mb2"><span>Our</span> Expertise
          </h2>
          <div className='flex-sb block-m'>
            {items.map((item, index) =>
              <a className="grid_3 exprt" key={index}>
                <img className="img" src="./images/5px.png" />
                <span className='inner' onClick={(e) => handleClick(e,this)}>
                  <span className="face">
                    {item.svg}
                    <svg clipRule="evenodd" className="arrow" fillRule="evenodd" strokeLinejoin="round"
                      strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="m14.523 18.787s4.501-4.505 6.255-6.26c.146-.146.219-.338.219-.53s-.073-.383-.219-.53c-1.753-1.754-6.255-6.258-6.255-6.258-.144-.145-.334-.217-.524-.217-.193 0-.385.074-.532.221-.293.292-.295.766-.004 1.056l4.978 4.978h-14.692c-.414 0-.75.336-.75.75s.336.75.75.75h14.692l-4.979 4.979c-.289.289-.286.762.006 1.054.148.148.341.222.533.222.19 0 .378-.072.522-.215z"
                        fillRule="nonzero" />
                    </svg>
                    <span className="title">  
                        {item.title}
                    </span>
                  </span>
                  <span className="back">
                    <span className="reverse">
                      {item.headLine}
                      <span className="read-more" onClick={(e)=>handlePoUp(e,item)} >
                        Read more
                      </span>
                    </span>
                    <img src="./images/close.svg" className="close" />
                  </span>
                </span>


              </a>)}
          </div>
        </div>
      </section>
      <div id="popup" ref={popRef}>
        <div id="bg-close" onClick={()=>closePop()}></div>
        <div id="container"  className="container container-p">
            <a id="closePop" onClick={()=>closePop()}> <img src="./images/close.svg"/></a>
            <div id="popContent" ref={popContent}></div>
        </div>
    </div>
    </>
  )
}

export default Expertise
