import React, { useState } from 'react'
import InputForm from '../micro/inputForm'
import TextArea from '../micro/textArea'
import validation from "../micro/validation";
import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebase';

function Form() {
  const [formDet, setFormDet] = useState({ name: ``, family: ``, phone: ``, email: ``, message: `` })
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [inputs, setInputs] = useState(
    [
      {
        id: 0,
        type: 'text',
        label: 'First name*',
        name: 'name',
        required: true,
        errorMessag: 'Please enter your first name',
        minWidth: 2,
        cssCls: 'inputHolder',
        isValid: true
      },
      {
        id: 1,
        type: 'text',
        label: 'Last name*',
        name: 'family',
        required: true,
        errorMessag: 'Please enter your last name',
        minWidth: 2,
        cssCls: 'inputHolder',
        isValid: true
      },
      {
        id: 2,
        type: 'tel',
        label: 'Phone*',
        name: 'phone',
        required: true,
        errorMessag: 'Phone number should be only numbers and atleast 8 characters',
        required: true,
        minWidth: 8,
        maxWidth: 12,
        cssCls: 'inputHolder',
        isValid: true
      },
      {
        id: 3,
        type: 'email',
        label: 'Email',
        name: 'email',
        required: true,
        errorMessag: 'Please enter a valid email',
        cssCls: 'inputHolder',
        isValid: true
      },
      {
        id: 4,
        type: 'textarea',
        label: 'Message',
        name: 'message',
        rows: 4,
        cssCls: '',
        required: false,
        errorMessag: 'Please put some text',
        cssCls: '',
        isValid: true
      }


    ]);
  const handleChange = (e) => {
    setFormDet(formDet => ({
      ...formDet,
      [e.target.name]: e.target.value
    }));

  }

  const getToday = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear().toString().slice(-2);
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    return formattedDate
  }
  const sendEmail = () => {
    let newEmail = { ...formDet, checked: false, date: getToday() }
    setLoading(true)
    addDoc(collection(db, "Emails"), newEmail)
      .then(docRef => {
        setError(false)
        setEmailSent(true)
        setLoading(false)
      })
      .catch(error => {
        setError(true)
        setLoading(false)
      })
  }



  const handleSubmit = () => {
    setInputs(validation(inputs, formDet).outPut);
    if (!validation(inputs, formDet).isFormValid)
      return
    setInputs(inputs.map(i => ({ ...i, isValid: true })))
    sendEmail()


  }

  return (
    <>
      <span id="closeEnq"></span>
      <div id="enquire">

        {loading && <div id='loading'><span></span></div>}
        <a id="closeEnqBtn"><img src="./images/close.svg" /></a>
        <div className="innerEnq">
          {!emailSent &&
            <>
              <h2 className=''><span>Contact</span> Us</h2>
              <p>For more information, please contact us via below details or fill in the form  and we will be in touch. </p>
              <div className='flex-sb team-c mb20'>

                <div className="inputHolder">
                  <div className='flex-s'>
                    <img src="./images/sinax.png" />
                    <p>Sina Enayati</p>
                  </div>
                  <div className='e-holder'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill='#2186f2' width="24" height="24" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" /></svg>
                    <a href="mailto:sina@finsavvyfinance.com.au">sina@finsavvyfinance.com.au</a>
                  </div>
                  <div className='e-holder'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill='#2186f2' width="24" height="24" viewBox="0 0 24 24"><path d="M14.89 23.654c-7.367 3.367-18.802-18.86-11.601-22.615l2.107-1.039 3.492 6.817-2.082 1.026c-2.189 1.174 2.37 10.08 4.609 8.994.091-.041 2.057-1.007 2.064-1.011l3.521 6.795c-.008.004-1.989.978-2.11 1.033zm-1.538-13.409l2.917.87c.223-.747.16-1.579-.24-2.317-.399-.739-1.062-1.247-1.808-1.469l-.869 2.916zm1.804-6.058c1.551.462 2.926 1.516 3.756 3.051.831 1.536.96 3.263.498 4.813l-1.795-.535c.325-1.091.233-2.306-.352-3.387-.583-1.081-1.551-1.822-2.643-2.146l.536-1.796zm.95-3.187c2.365.705 4.463 2.312 5.729 4.656 1.269 2.343 1.466 4.978.761 7.344l-1.84-.548c.564-1.895.406-4.006-.608-5.882-1.016-1.877-2.696-3.165-4.591-3.729l.549-1.841z" /></svg>
                    <a href="tel:0413625293">0413625293 </a>
                  </div>
                </div>
                <div className="inputHolder">
                  <div className='flex-s'>
                    <img src="./images/ansax.png" />
                    <p>Ansa Ansari</p>
                  </div>
                  <div className='e-holder'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill='#2186f2' width="24" height="24" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" /></svg>
                    <a href="mailto:ansa@finsavvyfinance.com.au">ansa@finsavvyfinance.com.au</a>
                  </div>
                  <div className='e-holder'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill='#2186f2' width="24" height="24" viewBox="0 0 24 24"><path d="M14.89 23.654c-7.367 3.367-18.802-18.86-11.601-22.615l2.107-1.039 3.492 6.817-2.082 1.026c-2.189 1.174 2.37 10.08 4.609 8.994.091-.041 2.057-1.007 2.064-1.011l3.521 6.795c-.008.004-1.989.978-2.11 1.033zm-1.538-13.409l2.917.87c.223-.747.16-1.579-.24-2.317-.399-.739-1.062-1.247-1.808-1.469l-.869 2.916zm1.804-6.058c1.551.462 2.926 1.516 3.756 3.051.831 1.536.96 3.263.498 4.813l-1.795-.535c.325-1.091.233-2.306-.352-3.387-.583-1.081-1.551-1.822-2.643-2.146l.536-1.796zm.95-3.187c2.365.705 4.463 2.312 5.729 4.656 1.269 2.343 1.466 4.978.761 7.344l-1.84-.548c.564-1.895.406-4.006-.608-5.882-1.016-1.877-2.696-3.165-4.591-3.729l.549-1.841z" /></svg>
                    <a href="tel:0403432588">0403432588 </a>
                  </div>
                </div>


              </div>

              <div className="flex-sb">
                <InputForm {...inputs[0]} value={formDet.name} handleChange={handleChange} />
                <InputForm {...inputs[1]} value={formDet.family} handleChange={handleChange} />
              </div>
              <div className="flex-sb">
                <InputForm {...inputs[2]} value={formDet.phone} handleChange={handleChange} />
                <InputForm {...inputs[3]} value={formDet.email} handleChange={handleChange} />
              </div>
              <TextArea {...inputs[4]} value={formDet.message} handleChange={handleChange} />
              <input type="submit" id="submit" className="btn-sub" onClick={() => handleSubmit()} value="Submit" />

            </>

          }
          {error && <h3 style={{ paddingTop: `30px`, color: `red` }}>The form could not be submitted please check you conntection and try again</h3>}
        </div>
        {emailSent &&
          <>
            <h2>Thank you </h2>
            <h4>We'll be in touch shortly.
              <br />Finsavvy Finance team
            </h4>
          </>}
      </div>
    </>
  )
}

export default Form
