import React from 'react'

const About = () => {
    return (
        <section className="pt150  bg-w" id="about">
            <div className='container'>
                <h2 className='mb2 center-align'><span>The </span> Team</h2>
                {/* <div className='grid_6 mb20'>
                    <p className='mb20'><b>Welcome to Finsavvy Finance.</b></p>
                        <p className='mb20'>We’ve experienced the frustration of existing customers receiving a higher rate than new customers first hand, so we decided to do something about it.</p>
               
                        <p>By working with us, you can save money, pay off your debt faster, and achieve your financial goals without breaking a sweat. We're committed to finding the right solution for you, so you can feel confident and secure in your finances.</p>
                    </div> */}
                <div className='flex-sb'>
                    
                    <div className='grid_th'>
                        <img className="lazyImg mb10 img " src="./images/ansax.jpg" data-src="./images/ansa.jpg" alt="Ansa Ansari" />
                     
                        <p className='center-align'>
                            <b>Ansa Ansari<br/>
                        Director<br/>MBus, Cert IV FMB, RG146
                        </b>
                        </p>


                    </div>
                    <div className='grid_th'>
                        <img className="lazyImg mb10 img " src="./images/sinax.jpg" data-src="./images/sina.jpg" alt="Sina Enayati" />
                        <p className='center-align'>
                           <b>Sina Enayati<br/>
                        Director<br/>BCom, Cert IV FMB, RG146
                        </b> 
                        </p>
                    </div>
                    <div className='grid_th'>
                        <img className="lazyImg mb10 img " src="./images/chrliex.jpg" data-src="./images/chrlie.jpg" alt="Chrlie Ansari" />
                     
                        <p className='center-align'>
                            <b>Charlie Lo Surdo<br/>
                        Mortgage Broker<br />Cert IV FMB, RG146
                        </b>
                        </p>


                    </div>

                </div>
                {/* <div className='flex-sb flex-reverse' >
                    <div className='grid_6'>
                        <p className='mb20'>Our goal is to help you to start saving money on your home loan. Our team will check in on your home loan every six months to make sure you're still getting the best deal possible. You won’t even need to ask.</p>
                        <p className='mb20'>We'll keep an eye on changes in the market, look at your financial situation, and see if there are any opportunities to reduce your rate or switch to a better lender. We’ll help you save money, reduce your debt, and achieve your financial goals quicker.</p>
                        <p>Let Finsavvy Finance help you take control of your finances and start building the future you deserve.

                        </p>
                    </div>
            

                </div> */}
                {/* <div className='flex-sb'>
                    <div className='grid_6'>
                     <p className='mb20'><b>Welcome to Finsavvy Finance.</b></p>
                        <p className='mb20'>We’ve experienced the frustration of existing customers receiving a higher rate than new customers first hand, so we decided to do something about it.</p>
               
                        <p>By working with us, you can save money, pay off your debt faster, and achieve your financial goals without breaking a sweat. We're committed to finding the right solution for you, so you can feel confident and secure in your finances.</p> 
                    </div>
                

                </div> */}
            </div>
            <a className='contact' id='contactF'>Contact Us</a>
        </section>
    )
}

export default About
