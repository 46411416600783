import React from 'react'

const Benefits = () => {
  return (
    <section className="ptb150 bg-white" id="benefits">
      <div className="container">
        <h2 className="center-align mb2"> <span>The</span> Benefits
        </h2>
        <div className='flex-sb align-top'>
          <div className='grid_3 '>
          <div className='w260'>
            <img className="b-icon " src="./images/Fast.png" alt="fast" />
            <h3 className='mb2'>Fast</h3>
            <p>We know the process and we’ll make sure it happens as quick as possible for you.
            </p>
            </div>
          </div>

          <div className='grid_3 '>
          <div className='w260'>
            <img className="b-icon " src="./images/Exclusive.png" alt="exclusive" />
            <h3 className='mb2'>Exclusive</h3>
            <p>Leverage our network and expertise for interest rate discounts and the best offers in the market.
            </p>
          </div>
          </div>
          <div className='grid_3 '>
          <div className='w260'>
            <img className="b-icon " src="./images/Support.png" alt="exclusive" />
            <h3 className='mb2'>Convenient</h3>
            <p>We’ll take care of all of the admin and do all of the chasing for you. You won’t have to do much. 
            </p>
            </div>
          </div>
          <div className='grid_3 '>
          <div className='w260'>
            <img className="b-icon " src="./images/Technology.png" alt="exclusive" />
            <h3 className='mb2'>Technology</h3>
            <p>Our awesome platform makes the whole process super easy to complete. 
            </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Benefits













