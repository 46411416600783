
import React,{useRef} from 'react'
import Nav from './nav';
const Footer = () => {
    const popRef=useRef();
    function openPop(){
        popRef.current.style.display=`flex`;
        setTimeout(function(){
          popRef.current.style.opacity=`1`;
        },5)
    
    }
    function closePop(){
      popRef.current.style.opacity=`0`;
        setTimeout(function(){
          popRef.current.style.display=`none`;
        },705)
    
    }

    return (
        <>
            <footer id="footer">
                <div className="container">

                    <a href="/" className="footer-logo" >
                        <img className="img mb20" src="./images/logo-b.png" alt="Finsavvy logo" />
                        <b>Finsavvy Finance Pty Ltd<br />ABN: 54 665 455 541</b> <br/><b>ACL: 509484 </b>
                    </a>

             
                    <ul id="ul-f" >
                        <Nav />
                    </ul>
                    <div className='right-align'><a onClick={()=>openPop()}>Privacy Policy</a><br />
                     
                    </div>
                </div>
                <div className='container  copy-r'>
                    <p>
               &copy;2023 Finsavvy Finance Pty Ltd. All rights reserved.
               </p>

                </div>


            </footer>
            <div id="privacy" ref={popRef}>
            <div id="bg-close-d" onClick={()=>closePop()}></div>

                <div className='container container-p' id='container-p'>
                <a id="closeDis" onClick={()=>closePop()}> <img src="./images/close.svg"/></a>
                <h3>Introduction</h3>

                <p>This privacy policy (“Policy”) outlines the types of personal information that Finsavvy Finance Pty Ltd (“we”, “us”, or “our”) collects, how we use and protect that information, and your rights with respect to your personal information. This Policy applies to all personal information collected through our website www.finsavvyfinance.com.au (“Website”). </p>



                <h3>Collection of Personal Information</h3>

                <p>We collect personal information that you provide to us when you:<br/>

                    <span className='dot'></span>Contact us through our customer service channels;<br/>

                    <span className='dot'></span>Use our Website.</p>



                <p>The types of personal information we may collect include:</p>

                <p>
                <span className='dot'></span>Name and contact information (such as email address, mailing address, and phone number);<br/>
                <span className='dot'></span>Usernames and passwords;<br/>
                <span className='dot'></span>Purchase history and preferences;<br/>
                <span className='dot'></span>Demographic information (such as age, gender, and interests); and<br/>
                <span className='dot'></span>Device and usage information (such as IP address, browser type, and referring URL).</p>


                <h3>Use of Personal Information</h3>

                <p>We use personal information for the following purposes:</p>

            <p>
            <span className='dot'></span>To provide and improve our Website, and to personalize your experience;<br/>
            <span className='dot'></span>To process and fulfill your orders, and to provide customer service;<br/>
            <span className='dot'></span>To send you promotional materials and other communications;<br/>
            <span className='dot'></span>To conduct surveys, contests, and other promotions;<br/>
            <span className='dot'></span>To analyse and understand our customer base, and to develop new products and services;<br/>
            <span className='dot'></span>To comply with legal obligations and protect our rights and interests; and<br/>
            <span className='dot'></span>For any other purposes disclosed at the time of collection.</p>

            <h3>Disclosure of Personal Information</h3>

            <p> We may disclose personal information to third parties for the following purposes:</p>

            <p>
            <span className='dot'></span>Service providers: We may share personal information with service providers that perform services on our behalf. These service providers may access personal information only as necessary to perform their functions and are required to keep the information confidential.<br/>
            <span className='dot'></span>Business partners: We may share personal information with our business partners, such as co-sponsors of a promotion, but only if you have consented to such sharing.<br/>
            <span className='dot'></span>Legal obligations: We may disclose personal information if we believe that disclosure is necessary to comply with applicable laws or regulations, respond to a subpoena, court order, or other legal process, or to protect the rights, property, or safety of our customers, employees, or others.<br/>
            <span className='dot'></span>Sale or transfer of business: We may disclose personal information in connection with a sale or transfer of all or a portion of our business or assets.</p>


            <h3>Data Security</h3>

            <p>We take reasonable measures to protect personal information from unauthorized access, use, disclosure, alteration, or destruction. We use physical, electronic, and administrative safeguards that are appropriate for the sensitivity of the information.</p>



            <h3>Your Rights</h3>

            <p>You have certain rights with respect to your personal information, subject to applicable law, including:</p>

            <p> <span className='dot'></span>The right to access and correct your personal information;<br/>
            <span className='dot'></span>The right to request that we delete your personal information;<br/>
            <span className='dot'></span>The right to object to the processing of your personal information;<br/>
            <span className='dot'></span>The right to request that we restrict the processing of your personal information;<br/>
            <span className='dot'></span>The right to data portability; and<br/>
            <span className='dot'></span>The right to withdraw your consent, if we are processing your personal information based on your consent.</p>


            <p>To exercise any of these rights, please contact us using the contact information provided.</p>



            <h3>Updates to this Policy</h3>

            <p>We may update this Policy from time to time by posting a revised version on our Website and App. The revised version will be effective as of the date of posting.</p>
            </div>
        </div>
    </>

  )
}

export default Footer
