
import { initializeApp } from "firebase/app";
import {getFirestore} from '@firebase/firestore'
const firebaseConfig = {
  apiKey: "AIzaSyA-qkhT84U4Z9AHv_AYRx4DXqif4wuSB0U",
  authDomain: "finsavvy-199c4.firebaseapp.com",
  projectId: "finsavvy-199c4",
  storageBucket: "finsavvy-199c4.appspot.com",
  messagingSenderId: "802528380860",
  appId: "1:802528380860:web:598eefe38d26abd54f8db9"
};


const app = initializeApp(firebaseConfig);
export const db =getFirestore(app)