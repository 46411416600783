import React from 'react'

function InputForm(props) {
  const { cssCls, label, errorMessag, isValid,   handleChange,  type,  value } = props;


  return (
    
      <div className={cssCls }>
        <label htmlFor={props.name}>{label}</label> <input type={type} name={props.name} onChange={(e) => handleChange(e)} value={(value) ? value : ''}  />
        {!isValid && <span className={isValid.toString()}>{errorMessag}</span>}
      </div>


    
  )
}

export default InputForm
