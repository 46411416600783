import React from 'react'

function TextArea(props) {

  const { cssCls, label, errorMessag, isValid, rows,  handleChange,  type,  value } = props;

 

  return (
  
      <div className={cssCls}>
        <label htmlFor={props.name}>{label}</label> 
        <textarea type={type} name={props.name} rows={rows} onChange={(e) => handleChange(e)} value={(value) ? value : ''}></textarea>
        {!isValid && <span className={isValid.toString()}>{errorMessag}</span>}
      </div>
    


  )
}

export default TextArea
