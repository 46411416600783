import React from 'react'

const Solution = () => {
  return (
    <section className="ptb150 bg-blue" id="solution">
    <div className="container">
      <h2 className="center-align mb2"> <span>Quick</span> Solutions
      </h2>
      <div className='flex-sb align-top'>
        <div className='grid_33 '>
            <div className='w260'>
          <img className="bb-icon " src="./images/capacity.png" alt="capacity" />
          <h3 className='mb2'>Borrowing Power</h3>
          <p>We can quickly calculate your borrowing power so you can begin the process with peace of mind. 
          </p>
          </div>
        </div>

        <div className='grid_33 '>
        <div className='w260'>
          <img className="bb-icon " src="./images/Market.png" alt="Market" />
          <h3 className='mb2'>Market</h3>
          <p>We can get you up to speed with the best rates and incentives in the market.
          </p>
          </div>
        </div>
        <div className='grid_33 '>
        <div className='w260'>
          <img className="bb-icon " src="./images/Partners.png" alt="Partners" />
          <h3 className='mb2'>Partners </h3>
          <p>We can connect you with quality solicitors, buyers agents, real estate agents and accountants. 
          </p>
        </div>
        </div>
   
      </div>
    </div>
  </section>
  )
}

export default Solution







