import { useState } from "react"
const Process = () => {
    const [activeIndex,setActiveIndex]=useState(0);
    const handleClick=(i)=>
    {
        setActiveIndex(i)
    }
    const processList = [
        {
            title: <h3>We will take a close look at your financial situation.</h3>,
            copy: `To uncover your unique needs, goals, and priorities.`,
            img: { lazySrc: `/images/l1x.png`, mainSrc: `/images/l1.png` ,cls:''}
        },
        {
            title: <h3>We will find the perfect loan products from our lender network.</h3>,
            copy:`Then we’ll craft a plan to achieve your short and long term financial goals.`,
            img: { lazySrc: `/images/l2x.png`, mainSrc: `/images/l2.png`  ,cls:'sml'}
        },
        {
            title: <h3>We will present you with solutions tailored to meet your objectives.</h3>,
            copy: `Allowing you to confidently choose what’s best for you.`,
            img: { lazySrc: `/images/l3x.png`, mainSrc: `/images/l3.png` ,cls:'sml'}

        },
        {
            title: <h3>We  will handle the preparation, submission, and follow up of your application.</h3>,
            copy: `To ensure a seamless and hassle-free approval process.`,
            img: { lazySrc: `/images/l4x.png`, mainSrc: `/images/l4.png` ,cls:'sml'}

        }, {
            title: <h3>We will help you to review and finalise loan documents.</h3>,
            copy: `Then we'll liaise with all relevant parties (banks, accountants, solicitors) to ensure a
   stress-free settlement.`,
            img: { lazySrc: `/images/l5x.png`, mainSrc: `/images/l5.png` ,cls:'' }

        }, {
            title: <h3>We will review your mortgage every 6 months <span className="blue">for the life of the loan.</span></h3>,
            copy: `To guarantee that you are always receiving the best possible interest rate.`,
            img: { lazySrc: `/images/l6x.png`, mainSrc: `/images/l6.png` ,cls:'' }

        }
    ]
    return (
        <section className="bg-blue ptb150" id="process">
            <div className="container flex-sb fullm">
                <div className="w300">
                    <h2>
                        We’ll guide you<br />
                        <span>through the process.</span>
                        <span className="sml">It’s a piece of cake. </span>

                    </h2>
                </div>
                <div className="v_w300 ">
                    <div className="slider">
                        <div className="control">
                            {processList.map((l,index)=><a key={index} onClick={()=>handleClick(index)} className={activeIndex===index?`step step${index+1} active`:`step step${index+1}`}><span>Step {index+1}</span></a>)}
                      
                        </div>
                        {processList.map((l,index)=><div key={index}  className={activeIndex===index?`slider-content  active`:`slider-content`}>
                        <div className="slider-img"><img className={`lazyImg ${l.img.cls}`} src={l.img.lazySrc } data-src={l.img.mainSrc}/>
                            </div>
                            <div className="slider-copy">
                            {l.title}
                                <p>
                                    {
                                        l.copy
                                    }
                                </p>
                            </div>
                            
                        </div>)}
                    
                    </div>
                </div>
            </div>
        </section>


    )
}

export default Process
