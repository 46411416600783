import React from 'react'

const Logos = () => {
  return (
    <section className="ptb150 bg-w" id="lenders">
    <div className="container">
        <h3 className="b-line">We match your needs and objectives to the best loan products of <span className='blue'> over 30 lenders.</span> </h3>
        <div className="logos">
            <span className="logoSp">
                <img src="./images/ubank.svg" className="ubank" alt="ubank"/>
            </span>
            <span className="logoSp">
                <img src="./images/inG.svg" className="inG" alt="inG"/>
            </span>
            <span className="logoSp">
                <img src="./images/westpac.svg" className="westpac" alt="westpac"/>
            </span>
            <span className="logoSp">
                <img src="./images/combank.svg" className="combank" alt="combank"/>
            </span>
            <span className="logoSp">
                <img src="./images/nab.svg" className="nab" alt="nab"/>
            </span>
            <span className="logoSp">
                <img src="./images/mqBank.svg" className="mqBank" alt="mqBank"/>
            </span>
        </div>
        <div className="logos">
            <span className="logoSp">
                <img src="./images/abl-logo.png" className="abl" alt="abl"/>
            </span>
            <span className="logoSp">
                <img src="./images/ANZ-logo.svg" className="anz" alt="anz logo"/>
            </span>
            <span className="logoSp">
                <img src="./images/bankwest.png" className="bankwest" alt="bankwest logo"/>
            </span>
            <span className="logoSp">
                <img src="./images/Stgeorge.svg" className="Stgeorge" alt="Stgeorge logo"/>
            </span>
            <span className="logoSp">
                <img src="./images/suncorp.png" className="suncorp" alt="suncorp logo"/>
            </span>
            <span className="logoSp">
                <img src="./images/ncp.svg" className="ncp" alt="ncp logo"/>
            </span>
            <span className="logoSp">
                <img src="./images/amp.png" className="amp" alt="amp logo"/>
            </span>
        </div>
        </div>
</section>
  )
}

export default Logos
