import React from 'react'

const Phone = () => {
    return (
        <div id="iphone" className="iphone">
            <div className="iphone-camera">
                <span className="s-camera"></span>
            </div>
            <div className="iphone-screen">
                <div className="screen-g">
                    <span className="anthena"></span>
                    <svg className="svg-icon  wi-fi " width="24" height="24" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                        <path
                            d="M12 20c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm-3.659-3.254c1.013-.781 2.282-1.246 3.659-1.246 1.376 0 2.645.464 3.657 1.245l-.572.821c-.85-.667-1.921-1.066-3.085-1.066s-2.237.399-3.087 1.068l-.572-.822zm-2.859-4.105c1.825-1.346 4.079-2.141 6.518-2.141 2.437 0 4.691.794 6.515 2.139l-.572.821c-1.662-1.232-3.718-1.96-5.943-1.96-2.226 0-4.284.729-5.946 1.962l-.572-.821zm-2.858-4.104c2.635-1.91 5.875-3.037 9.376-3.037 3.499 0 6.737 1.125 9.371 3.034l-.571.821c-2.472-1.796-5.513-2.855-8.8-2.855-3.288 0-6.331 1.06-8.804 2.858l-.572-.821zm-2.624-3.768c3.402-2.375 7.54-3.769 12-3.769 4.458 0 8.593 1.392 11.994 3.765l-.571.82c-3.239-2.259-7.178-3.585-11.423-3.585-4.248 0-8.188 1.327-11.429 3.589l-.571-.82z" />
                    </svg>
                    <svg className="svg-icon " xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24">
                        <path
                            d="M19 8v8h-17v-8h17zm2-2h-21v12h21v-12zm1 9h.75c.69 0 1.25-.56 1.25-1.25v-3.5c0-.69-.56-1.25-1.25-1.25h-.75v6zm-11-6h-8v6h8v-6z" />
                    </svg>
                    <span className="time-g">10:55</span>
                </div>
                <div className="iphone-inbox">
                    <div className=" phone-inbox-header flex-sb"><span className="back-mail">&nbsp;
                    </span><span>Edit</span></div>

                    <div className=" phone-inbox-header-det">
                        <div className="iphone-msg">
                            <div className="blue-bar"><img src="./images/logo.png"/></div>
                            <span className="check-c"><svg clipRule="evenodd" fill="#2186f2"
                                fillRule="evenodd"strokeLinejoin="round" strokeMiterlimit="2"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm0 1.5c-4.69 0-8.498 3.807-8.498 8.497s3.808 8.498 8.498 8.498 8.497-3.808 8.497-8.498-3.807-8.497-8.497-8.497zm-5.049 8.886 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z"
                                    fillRule="nonzero" />
                            </svg></span>
                            <p>
                                <b>Congratulations!</b>
                            </p>

                            <p>We have just reviewed your home loan and have dropped your interest rate
                                for&nbsp;you.</p>
                            <p>Your repayments have been reduced by <b>$300 per month</b> and this
                                comes into effect in your next repayment.</p>

                            <p>As always, we will be in touch to discuss any better offers or savings that
                                we can take advantage of. </p>
                                <p>Kind regards,<br/>
                                Finsavvy  Team
                                </p>

                        </div>
                        <h3 className="move-top">Inbox</h3>
                        <div className="move-top phone-inbox-main flex-sb">
                            <div className="phone-inbox-sender">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24">
                                        <path fill="#fff"
                                            d="M20 7.093v-5.093h-3v2.093l3 3zm4 5.907l-12-12-12 12h3v10h18v-10h3zm-5 8h-14v-10.26l7-6.912 7 6.99v10.182zm-5-1h-4v-6h4v6z" />
                                    </svg></span>
                            </div>
                            <div className="phone-inbox-email">
                                <h4>Finsavvy <span>Today</span></h4>
                                <div className="phone-inbox-sub">
                                    <h5>Loan review</h5>
                                    <div className="phone-inbox-inner flex-sb">

                                        <p>Congratulations! We have just reviewed your home loan and have   <b>dropped your interest ...</b>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div id="move-d">
                        <div className=" phone-inbox-main flex-sb">
                            <div className="phone-inbox-sender" style={{backgroundColor: '#a4a1a1'}}>
                                <span>
                                    AM
                                </span>
                            </div>
                            <div className="phone-inbox-email blur">
                                <h4>abcd <span>Today</span></h4>
                                <div className="phone-inbox-sub">
                                    <h5>abcd efgh</h5>
                                    <div className="phone-inbox-inner flex-sb">

                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className=" phone-inbox-main flex-sb">
                            <div className="phone-inbox-sender" style={{backgroundColor: '#9b7676'}}>
                                <span>
                                    TM
                                </span>
                            </div>
                            <div className="phone-inbox-email blur">
                                <h4>abcd <span>Today</span></h4>
                                <div className="phone-inbox-sub">
                                    <h5>abcd efgh</h5>
                                    <div className="phone-inbox-inner flex-sb">

                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className=" phone-inbox-main flex-sb">
                            <div className="phone-inbox-sender"  style={{backgroundColor: '#de5e5e'}} >
                                <span>
                                    SE
                                </span>
                            </div>
                            <div className="phone-inbox-email blur">
                                <h4>abcd <span>Today</span></h4>
                                <div className="phone-inbox-sub">
                                    <h5>abcd efgh</h5>
                                    <div className="phone-inbox-inner flex-sb">

                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
        <div className="iphone-front"></div>
            <div className="iphone-back">
                <span className="iphone-btn"></span>
                <span className="iphone-btn btn2"></span>
                <span className="iphone-charger"></span>
                <span className="iphone-speaker "  style={{bottom: '#2%' ,left:'27%'}} ></span>
                <span className="iphone-speaker " style={{bottom: '#2%' ,left:'39%'}} ></span>
                <span className="iphone-speaker " style={{bottom: '#2%' ,left:'35%'}} ></span>
                <span className="iphone-speaker " style={{bottom: '#2%' ,left:'31%'}} ></span>
                <span className="iphone-speaker " style={{bottom: '#2%' ,left:'63%'}} ></span>
                <span className="iphone-speaker " style={{bottom: '#2%' ,left:'67%'}} ></span>
                <span className="iphone-speaker "  style={{bottom: '#2%' ,left:'71%'}}></span>
                <span className="iphone-speaker " style={{bottom: '#2%' ,left:'75%'}} ></span>
            </div>
</div>

    )
}

export default Phone
