import React from 'react'
import Phone from './phone'

const Topsection = () => {
    return (

        <section>
            <div id="landing">
                <div className="l-left ">
                    <div className="w500">
                        <h1>Home loans that are<br/>competitive for life

</h1>
                        <h3>We guarantee you’ll always stay&nbsp;ahead. 


                        </h3>
                    </div>

                </div>
                <div className="l-right">
                    <img className="hand lazyImg" src="./images/hanx.jpg" data-src="./images/hand.jpg" alt="hand-house" />
                </div>

            </div>
            <div className="svg-holder">
                <svg xmlns="http://www.w3.org/2000/svg" width="2.17" heigth="35.28" viewBox="0 0 2.17 35.28"
                    preserveAspectRatio="none">

                    <path
                        d="M1 35.28c.8-3.17-.38-7.3-.55-10.04-.17-2.76 1.47-9.25 1.3-12.68C1.58 9.14.91 7.92.88 5.52.86 3.12 1.76 0 1.76 0H0v35.28z"
                        fill="#2186f2" />
                </svg>

            </div>

            <div className="content ptb150" id="loyalty">
                <div className="container flex-sb" style={{flexDirection:'row-reverse'}}>
                  
                    <div className="grid_6">
                        <h2 className='mb2'>Regular<span> Rate Review</span>
                        </h2>

                        <div className='royal'>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#2186f2' height="48" viewBox="0 96 960 960" width="48"><path d="M480 976q-83 0-156-31.5t-127-86Q143 804 111.5 731T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 155.5 31.5t127 85.5q54.5 54 86 127T880 576q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480 976Zm0-60q141 0 240.5-99.5T820 576q0-142-99.5-241T480 236q-142 0-241 99t-99 241q0 141 99 240.5T480 916Zm73-391 14-8q3 20 18 33t35 13q23 0 38.5-15.5T674 509q0-13-5.5-24T653 466l27-15-20-35-127 73 20 36Zm-146 0 20-36-127-73-20 35 27 15q-10 8-15.5 19t-5.5 24q0 23 15.5 38.5T340 563q20 0 35-13t18-33l14 8Zm73 94q-69 0-121 45.5T281 776h398q-27-65-78.5-111T480 619Zm0-43Z" /></svg>
                            </span>
                            <p>Are you sick of loyal existing customers receiving a higher interest rate than new to bank customers?</p>
                        </div>
                        <div className='royal'>
                            <span>

                                <svg xmlns="http://www.w3.org/2000/svg" fill='#2186f2' height="48" viewBox="0 96 960 960" width="48"><path d="M240 976V804q-57-52-88.5-121.5T120 536q0-150 105-255t255-105q125 0 221.5 73.5T827 441l55 218q4 14-5 25.5T853 696h-93v140q0 24.75-17.625 42.375T700 896H600v80h-60V836h160V636h114l-45-180q-24-97-105-158.5T480 236q-125 0-212.5 86.5T180 533.54q0 64.417 26.324 122.392Q232.649 713.908 281 759l19 18v199h-60Zm257-370Zm-48 76h60l3-44q12-2 22.472-8.462Q544.944 623.077 553 615l42 14 28-48-30-24q5-14 5-29t-5-29l30-24-28-48-42 14q-8.333-7.692-19.167-13.846Q523 421 512 418l-3-44h-60l-3 44q-11 3-21.833 9.154Q413.333 433.308 405 441l-42-14-28 48 30 24q-5 14-5 29t5 29l-30 24 28 48 42-14q8.056 8.077 18.528 14.538Q434 636 446 638l3 44Zm30.118-84Q450 598 429.5 577.618q-20.5-20.383-20.5-49.5Q409 499 429.382 478.5q20.383-20.5 49.5-20.5Q508 458 528.5 478.382q20.5 20.383 20.5 49.5Q549 557 528.618 577.5q-20.383 20.5-49.5 20.5Z" /></svg>
                            </span>
                            <p>We understand how frustrating this can be, so we’ve taken matters into our own hands.</p>
                        </div>
                        <div className='royal'>
                            <span>

                                <svg xmlns="http://www.w3.org/2000/svg" fill='#2186f2' height="48" viewBox="0 96 960 960" width="48"><path d="M405 656h315v-60H465l-60 60Zm-165 0h79l252-249q6-6 6-15t-6-15l-55-50q-5-5-12.5-5t-12.5 5L240 582v74ZM80 976V236q0-23 18-41.5t42-18.5h680q23 0 41.5 18.5T880 236v520q0 23-18.5 41.5T820 816H240L80 976Zm60-145 75-75h605V236H140v595Zm0-595v595-595Z" /></svg>
                            </span>
                            <p> We will review your mortgage every six months, making sure that you are always getting    the best   deal.</p>
                        </div>
                        <div className='royal'>
                            <span><svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 0 48 48" fill='#2186f2' width="48"><path d="m31.15 29.95 1.6-1.6-4.4-4.4q.55-.85.925-1.775.375-.925.375-1.925 0-2.85-1.95-4.8-1.95-1.95-4.8-1.95-2.85 0-4.8 1.95-1.95 1.95-1.95 4.8 0 2.85 1.95 4.8Q20.05 27 22.9 27q1.1 0 2.075-.375.975-.375 1.925-.925ZM22.9 24.5q-1.75 0-3-1.25t-1.25-3q0-1.75 1.25-3t3-1.25q1.75 0 3 1.25t1.25 3q0 1.75-1.25 3t-3 1.25ZM3.5 42q-.65 0-1.075-.425Q2 41.15 2 40.5q0-.65.425-1.075Q2.85 39 3.5 39h41q.65 0 1.075.425Q46 39.85 46 40.5q0 .65-.425 1.075Q45.15 42 44.5 42ZM7 36q-1.2 0-2.1-.9Q4 34.2 4 33V9q0-1.2.9-2.1Q5.8 6 7 6h34q1.2 0 2.1.9.9.9.9 2.1v24q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h34V9H7v24Zm0 0V9v24Z"/></svg>
                            </span>
                            <p>If we identify an opportunity to get your rate lower, we will contact your lender on your behalf and reduce your interest rate for you.</p>
                        </div>
                        <div className='royal'>
                            <span>


                                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" fill='#2186f2' width="48"><path d="M475 916q5 0 11.5-2.5T497 907l337-338q13-13 19.5-29.667Q860 522.667 860 506q0-17-6.5-34T834 442L654 262q-13-13-30-19.5t-34-6.5q-16.667 0-33.333 6.5Q540 249 527 262l-18 18 81 82q13 14 23 32.5t10 40.5q0 38-29.5 67T526 531q-25 0-41.5-7.5t-30.185-21.341L381 429 200 610q-5 5-7 10.526-2 5.527-2 11.842 0 12.632 8.5 21.132 8.5 8.5 21.167 8.5 6.333 0 11.833-3t9.5-7l138-138 42 42-137 137q-5 5-7 11t-2 12q0 12 9 21t21 9q6 0 11.5-2.5t9.5-6.5l138-138 42 42-137 137q-4 4-6.5 10.333Q361 794.667 361 801q0 12 9 21t21 9q6 0 11-2t10-7l138-138 42 42-138 138q-5 5-7 11t-2 11q0 14 8 22t22 8Zm.064 60Q442 976 416 951.5t-31-60.619Q351 886 328 863t-28-57q-34-5-56.5-28.5T216 721q-37-5-61-30t-24-60q0-17 6.724-34.049T157 567l224-224 110 110q8 8 17.333 12.5Q517.667 470 527 470q13 0 24.5-11.5t11.5-24.654q0-5.846-3.5-13.346T548 405L405 262q-13-13-30-19.5t-34-6.5q-16.667 0-33.333 6.5Q291 249 278.059 261.857L126 414q-14 14-19.5 29.5t-6.5 35q-1 19.5 7.5 38T128 550l-43 43q-20-22-32.5-53T40 477q0-30 11.5-57.5T84 371l151-151q22-22 49.793-32.5 27.794-10.5 57-10.5Q371 177 398.5 187.5T448 220l18 18 18-18q22-22 49.793-32.5 27.794-10.5 57-10.5Q620 177 647.5 187.5T697 220l179 179q22 22 33 50.033t11 57Q920 535 909 562.5T876 612L539 949q-13 13-29.532 20t-34.404 7ZM377 430Z" /></svg>
                            </span>
                            <p>We take care of everything for you, so you can sit back and relax, knowing that your mortgage is in good hands.</p>
                        </div>



                    </div>
                    <div className="grid_6">

<Phone />
</div>
                </div>

            </div>



        </section>
    )
}

export default Topsection
